module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jeremy Philipson | Website","short_name":"Jeremy Philipson","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"src/images/logo_black.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
